/* Generated by Glyphter (http://www.glyphter.com) on  Sun Mar 27 2022*/
@font-face {
    font-family: 'surgepay';
    src: url('../fonts/surgepay.eot');
    src: url('../fonts/surgepay.eot?#iefix') format('embedded-opentype'),
         url('../fonts/surgepay.woff') format('woff'),
         url('../fonts/surgepay.ttf') format('truetype'),
         url('../fonts/surgepay.svg#surgepay') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
	display: inline-block;
   font-family: 'surgepay';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.icon-home:before{content:'\0041';}
.icon-qr-scan:before{content:'\0043';}
.icon-more:before{content:'\0045';}
.icon-crypto-profile:before{content:'\0047';}
.icon-merchant:before{content:'\0048';}