/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/*
 * App CSS
 * ----------------------------------------------------------------------------
 * Imports a file that can contain Sass/CSS that should be used throughout
 * the entire app.
 */
 
 @import '~swiper/scss';
 @import '~@ionic/angular/css/ionic-swiper';
 @import '~swiper/scss/pagination';

//  Glyphter
 @import 'assets/fonts/glyphter/css/surgepay.css';

 .icons{
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
 }

 .rotate180{
   transform: rotateX(180deg);
 }
/* Loading Spinner */
.loader-spinner {
  z-index: 99000 !important;
}
 
/* placed here instead of intro.scss due to slide els not getting ng scoping attribute */
 .swiper-slide {
   display: block;
 }

 /*Modals*/
 .sub-wallet-modal{
    position: absolute;
    --width: 277px !important;
    --height: 249px !important;
    --border-radius: 20px;
    --overflow: hidden !important;
}

.confirm-delete-modal{
  position: absolute;
  --max-width: 301px !important;
  --max-height: 303px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
}

.move-success-modal{
  position: absolute;
  --max-width: 277px !important;
  --max-height: 352px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
}
.connection-modal{
  position: absolute;
  --max-width: 246px !important;
  --max-height: 235px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
}

.confirm-send-modal{
  position: absolute;
  --max-width: 277px !important;
  --max-height: 339px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
}

.confirm-convert-modal{
  position: absolute;
  --max-width: 277px !important;
  --max-height: 339px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
}

.convert-success-modal{
  position: absolute;
  --max-width: 277px !important;
  --max-height: 389px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
}

.convert-error-modal{
  position: absolute;
  --max-width: 277px !important;
  --max-height: 329px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
}

.send-success-modal{
  position: absolute;
  --max-width: 277px !important;
  --max-height: 389px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
}

.send-error-modal{
  position: absolute;
  --max-width: 277px !important;
  --max-height: 329px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
}

.qr-success-modal{
  position: absolute;
  --max-width: 277px !important;
  --max-height: 389px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
}

.login-error-modal{
  position: absolute;
  --max-width: 277px !important;
  --min-height: 339px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --height: calc(var(--min-height) + 1rem );  
}

.upgrade-modal{
  position: absolute;
  --max-width: 329px !important;
  --max-height: 630px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
}

.datetimepicker-modal{
  position: absolute;
  --height: 100% !important;
  --max-width: 295px !important;
  --max-height: 435px !important;    
  --border-radius: 20px;
  --overflow: hidden !important;
}

.dashboard-qr-modal{
  position: absolute;
  --width: 270px !important;
  --height: 380px!important;
  --border-radius: 20px;
  --overflow: hidden !important;
}

.choose-img-source-modal{
  position: absolute;
  --width: 100% !important;
  --height: 150px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
}

.check-upgrade-modal{
  position: absolute;
  --width: 277px !important;
  --height: 257px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
}

.notification{
  --background:#FFAA2B;
}

@media screen and (max-width: 370px) {
  .upgrade-modal{
    --max-width: 275px !important;
    --max-height: 530px !important; 
    --padding: 2rem;   
    --overflow: hidden !important;
  }
}

.scan-failed-modal{
  position: absolute;
  --max-width: 282px !important;
  --max-height: 306px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
}

.scan-success-modal{
  position: absolute;
  --max-width: 282px !important;
  --max-height: 306px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
}

.withdraw-request-modal{
  position: absolute;
  --max-width: 277px !important;
  --max-height: 287px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
}

.withdraw-failed-modal{
  position: absolute;
  --max-width: 277px !important;
  --max-height: 329px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
}

.filter-transaction-modal{
  position: absolute;
  --min-width: 100% !important;
  --max-height: 470px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
  --border-radius: 30px;
}

  /* Font size */
.t-fs48{
  font-size: 48px;
}
.t-fs27-68{
  font-size: 27.68px;
}
.t-fs36{
  font-size: 36px;
}
.t-fs24{
  font-size: 24px;
}
.t-fs20{
  font-size: 20px;
}
.t-fs18{
  font-size: 18px;
}
.t-fs16-76{
  font-size: 16.76px;
}
.t-fs16{
  font-size: 16px;
}
.t-fs15{
  font-size: 15px;
}
.t-fs14{
  font-size: 14px;
}
.t-fs12{
  font-size: 12px;
}
.t-fs11-4{
  font-size: 11.4px;
}
.t-fs10-87{
  font-size: 10.87px;
}
.t-fs10{
  font-size: 10px;
}
.t-fs9-77{
  font-size: 9.77px;
}
.t-fs9{
  font-size: 9px;
}
.t-fs8-38{
  font-size: 8.38px;
}

 /* Line Height */
.t-lh65-37{
  line-height: 65.37px;
}
.t-lh37-7{
  line-height: 37.7px;
}
.t-lh33{
  line-height: 33px;
}
.t-lh32-68{
  line-height: 32.68px;
}
.t-lh28-96{
  line-height: 28.96px;
}
.t-lh27-24{
  line-height: 27.24px;
}
.t-lh25-2{
  line-height: 25.2px;
}
.t-lh24-51{
  line-height: 24.51px;
}
.t-lh24{
  line-height: 24px;
}
.t-lh22-83{
  line-height: 22.83px;
}
.t-lh21-79{
  line-height: 21.79px;
}
.t-lh21-72{
  line-height: 21.72px;
}
.t-lh20-43{
  line-height: 20.43px;
}
.t-lh19-31{
  line-height: 19.31px;
}
.t-lh19-07{
  line-height: 19.07px;
}
.t-lh16-34{
  line-height: 16.34px;
}
.t-lh16-34{
  line-height: 16.34px;
}
.t-lh15-52{
  line-height: 15.52px;
}
.t-lh15-4{
  line-height: 15.4px;
}
.t-lh14-66{
  line-height: 14.66px;
}
.t-lh14{
  line-height: 14px;
}
.t-lh13-62{
  line-height: 13.62px;
}
.t-lh13-15{
  line-height: 13.15px;
}
.t-lh12{
  line-height: 12px;
}
.t-lh10-87{
  line-height: 10.87px;
}
.t-lh10{
  line-height: 10px;
}
.t-lh11-41{
  line-height: 11.41px;
}


.h100{
  height: 100%;
}
.tr{
  text-align: right;
}



/* Font Weight */
.t-fwb{
  font-weight: bold;
}
.t-fw700{
  font-weight: 700;
}
.t-fw600{
  font-weight: 600;
}
.t-fw400{
  font-weight: 400;
}
.t-fw300{
  font-weight: 300;
}

.t-mt20{
  margin-top: 10px !important;
}

.pointer{
  cursor: pointer;
}

/* Images */
.landing-bg{
  background-image: url('./assets/img/landing-logo.svg');
  background-repeat: no-repeat;    
}
.holding-phone{
   background-image: url('./assets/img/landing-logo.svg');
   background-repeat: no-repeat;    
}

/* Color */
.green-landing{
  color: var(--ion-color-landing-green) !important;
}
.blue-landing{
 color: var(--ion-color-landing-blue) !important;
}

.blue-color{
  color: var(--ion-color-primary) !important;
}
.red-color{
  color: var(--ion-color-danger) !important;
}

/* Flex */
.absolute{
  position: absolute;
}

.column{
  display: flex;
  flex-direction: column;
}
.row{
  display: flex;
  flex-direction: row;
}
.flex-center{
  display: flex;
  align-items: center;
  justify-content: center;
 }
 .flex-start{
  display: flex;
  justify-content: flex-start;
 }
 .flex-end{
  display: flex;
  align-items: center;
  justify-content: flex-end;
 }

 .space-between{
  display: flex;
  align-items: center;
  justify-content: space-between;
 }
.align-item-center{
  display: flex;
  align-items: center;
 }
 .align-item-start{
  display: flex;
  align-items: flex-start;
 }

 /* Circle Button */
 .warning-circle{
   position: absolute;
   right: 1rem;
   z-index: 3;    
   background: red;
   border-radius: 999px;
 
   ion-icon{
     font-size: 1rem;
     display: block;
     color: #fff;
     padding: 3px;
   }
 }
/* Margin */
.m0{
  margin: 0;
}
.p0{
  padding: 0;
}
.plr1{
  padding: 0 1rem;
}
.plr2{
  padding: 0 2rem;
}

.r1{
  right: 1rem;
}

.no-margin{
  margin: 0px;
}

.mAuto{
  margin: auto;
}
.mtb1{
 margin: 1rem 0;
}
.mb1{
  margin-bottom: 1rem;
 }
 .mb2{
  margin-bottom: 20px;
 }
.mt0-4{
  margin-top: 0.4rem;
 }
 .mt0-5{
  margin-top: 0.5rem;
 }
.mt1{
  margin-top: 1rem;
 }
 .mt1-5{
  margin-top: 1.5rem;
 }
.mt2{
  margin-top: 2rem;
 }
 .mt3{
  margin-top: 3rem;
 }
 .mt3-5{
  margin-top: 3.5rem;
 }
 .mt4{
  margin-top: 5rem;
 }
.mt5{
 margin-top: 5rem;
}
.ml2{
  margin-left: 2rem;
}
.tj{
  text-align: justify;
}

/* width */
.w100{
  width: 100%;
}
.checkbox-square{
  --border-radius: 0%!important
}

/* Error Message */
.error{
  margin-top: 0.7rem;
  small{
      color: red;
      font-weight: 700;
      font-size: 12px;
  }    
}
/* Positions */
.relative{
  position: relative;
}


/* Default Button */
ion-button{    
 --border-radius: 30px;
 --font-family: 'Open Sans';
 --font-style: normal;
 --font-weight: 600;
 --font-size: 12px;
 --text-align: center;
 --padding: 1rem;
}
.pointer{
  cursor: pointer;
}

// Camera Preview
.custom-ion-content {
  background: none !important;
  height: 100%;
}